import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  // 数据
  state: {
    // 当前门店
    currentStore: JSON.parse(localStorage.getItem("CurrentStore")),
    // 是否为管理人员
    managerShow: false,
  },
  // 计算
  getters: {},
  // 同步定义方法 操作state
  mutations: {
    setCurrentStore(state, payload) {
      state.currentStore = JSON.parse(localStorage.getItem("CurrentStore"));
    },
    setManagerShow(state, payload) {
      state.managerShow = payload;
    },
  },
  // 操作异步 与 mutations
  actions: {},
  // 模块
  modules: {},
});
