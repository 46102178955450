import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 首页
import Index from "../views/index/index";

// 路由懒加载
// 登录
const Login = () => import("../views/login/login");
// 注册
const Register = () => import("../views/login/register");

// 收衣
const Pack = () => import("../views/index/pack");
// 线下收衣
const PackClothes = () => import("../views/pack/packClothes");
// 抖音团购
const PackTrill = () => import("../views/pack/packTrill");
// 团套洗
const PackTeam = () => import("../views/pack/packTeam");
// 多抖音团购
const PackGroup = () => import("../views/pack/packGroup");

// 订单
const Order = () => import("../views/index/order");
// 上架
const Shelf = () => import("../views/index/shelf");
// 充值中心
const Recharge = () => import("../views/index/recharge");
// 账号充值
const AccountRecharge = () => import("../views/recharge/accountRecharge");
// Vip充值
const VipRecharge = () => import("../views/recharge/vipRecharge");
// 充值记录
const AccountRecords = () => import("../views/recharge/accountRecords");
// 会员
const Member = () => import("../views/index/member");
// 用户
const AddUser = () => import("../views/index/addUser");
// 更多
const More = () => import("../views/index/more");
// 财务
const Financing = () => import("../views/more/financing");
// 打印
const Print = () => import("../views/more/print");
// 个人中心
const User = () => import("../views/index/user");
// 消息
const Message = () => import("../views/index/message");
// 404
const ThereNo = () => import("../views/404");

const routes = [
  {
    path: "/",
    redirect: "/Index",
  },
  {
    path: "/index",
    name: "Index",
    meta: {
      title: "二丫洗护",
      needLogin: true, // 是否需要登录
    },
    component: Index,
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录",
      needLogin: false, // 是否需要登录
    },
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      title: "注册",
      needLogin: false, // 是否需要登录
    },
    component: Register,
  },
  {
    path: "/pack",
    name: "Pack",
    meta: {
      title: "收衣",
      needLogin: true,
    },
    component: Pack,
    redirect: "/pack/packClothes", // 点击父级 默认选中该父目录下的子路径页面
    children: [
      {
        path: "packClothes",
        name: "PackClothes",
        component: PackClothes,
        meta: {
          title: "线下收衣",
          needLogin: true,
        },
      },
      {
        path: "packTrill",
        name: "PackTrill",
        component: PackTrill,
        meta: {
          title: "抖音团购",
          needLogin: true,
        },
      },
      {
        path: "packTeam",
        name: "PackTeam",
        component: PackTeam,
        meta: {
          title: "团套洗",
          needLogin: true,
        },
      },
      {
        path: "packGroup",
        name: "PackGroup",
        component: PackGroup,
        meta: {
          title: "抖音团购",
          needLogin: true,
        },
      },
    ],
  },
  {
    path: "/order",
    name: "Order",
    meta: {
      title: "订单中心",
      needLogin: true,
    },
    component: Order,
  },
  {
    path: "/shelf",
    name: "Shelf",
    meta: {
      title: "上架中心",
      needLogin: true,
    },
    component: Shelf,
  },
  {
    path: "/recharge",
    name: "Recharge",
    meta: {
      title: "充值中心",
      needLogin: true,
    },
    component: Recharge,
    redirect: "/recharge/accountRecharge", // 点击父级 默认选中该父目录下的子路径页面
    children: [
      {
        path: "accountRecharge",
        name: "AccountRecharge",
        component: AccountRecharge,
        meta: {
          title: "账号充值",
          needLogin: true,
        },
      },
      {
        path: "vipRecharge",
        name: "VipRecharge",
        component: VipRecharge,
        meta: {
          title: "Vip充值",
          needLogin: true,
        },
      },
      {
        path: "accountRecords",
        name: "AccountRecords",
        component: AccountRecords,
        meta: {
          title: "充值记录",
          needLogin: true,
        },
      },
    ],
  },
  {
    path: "/member",
    name: "Member",
    meta: {
      title: "会员",
      needLogin: true,
    },
    component: Member,
  },
  {
    path: "/addUser",
    name: "AddUser",
    meta: {
      title: "新增用户",
      needLogin: true,
    },
    component: AddUser,
  },
  {
    path: "/more",
    name: "More",
    meta: {
      title: "更多",
      needLogin: true,
    },
    component: More,
    redirect: "/More/financing", // 点击父级 默认选中该父目录下的子路径页面
    children: [
      {
        path: "financing",
        name: "Financing",
        component: Financing,
        meta: {
          title: "财务",
          needLogin: true,
        },
      },
      {
        path: "print",
        name: "Print",
        component: Print,
        meta: {
          title: "打印",
          needLogin: true,
        },
      },
    ],
  },
  {
    path: "/user",
    name: "User",
    meta: {
      title: "个人中心",
      needLogin: true,
    },
    component: User,
  },
  {
    path: "/message",
    name: "Message",
    meta: {
      title: "消息",
      needLogin: true,
    },
    component: Message,
  },
  {
    path: "/404",
    component: ThereNo,
    meta: {
      title: "404",
      needLogin: false,
    },
  },

  { path: "*", redirect: "/404", hidden: true },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path != from.path) {
    // 是否需要登录
    if (to.meta.needLogin) {
      if (localStorage.getItem("token")) {
        // 设置标题
        document.title = to.meta.title;
        next();
      } else {
        next("/login");
        // next()
      }
    } else {
      next();
    }
  }
});

export default router;
