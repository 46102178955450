import axios from "axios";
import { Message } from "element-ui";
import { BASE_URL, TIME_OUT } from "../../config.js";

export function request(config) {
  // console.log("config", config);

  const service = axios.create({
    baseURL: BASE_URL, //配置公共接口
    timeout: TIME_OUT, //配置请求超时时间
    responseType: config.responseType ? config.responseType : "", // 后端返回给我们的数据格式
  });

  // 请求拦截器
  service.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["token"] = token;
    }
    // console.log("请求拦截", config);
    return config;
  });

  // 响应拦截器
  service.interceptors.response.use(
    (res) => {
      // console.log("回调data", res.data);
      if (config.responseType) {
        return Promise.resolve(res.data);
      }
      if (res.status === 200) {
        if (res.data.code != 1) {
          Message({
            showClose: true,
            duration: 1500,
            message: res.data.msg,
            // center: true,
            type: "error",
          });
        } else {
          return Promise.resolve(res.data);
        }
      } else if (res.status === 401) {
        Message({
          showClose: true,
          duration: 1500,
          message: "未授权，请重新登录",
          // center: true,
          type: "warning",
        });
        this.$router.push("login");
      } else {
        Message({
          showClose: true,
          duration: 1500,
          message: "请求失败",
          // center: true,
          type: "error",
        });
      }
      // 请求出现错误时回调
      return Promise.reject(res.data);
    },
    (err) => {
      // console.log("回调错误数据", err);
      Message({
        showClose: true,
        duration: 1500,
        message: err.response.data.msg,
        type: "error",
      });
      return Promise.reject(err.response.data);
    }
  );
  return service(config);
}
