<template>
  <div class="indexs">
    <div class="edition">V{{ version }}</div>
    <div class="headers">
      <div class="Logo">
        <img src="../../assets/logo.png">
        <div>二丫5S洗护</div>
      </div>
      <div class="search">
        <el-autocomplete class="inline-input" v-model="searchValue" :fetch-suggestions="querySearch"
          placeholder="请输入关键字搜索" :trigger-on-focus="false" style="width: 100%;" @select="handleSelect">
          <el-select v-model="value" slot="prepend" placeholder="请选择">
            <el-option v-for="item in optionList" :label="item.label" :value="item.value" :key="item.value"></el-option>
          </el-select>
          <template v-slot="{ item }">
            <div v-html="light(item)" style="font-size:14px"></div>
            <span class="addr" v-if="value == 'consignee'">电话：{{ item.phone }}&nbsp;&nbsp;&nbsp;&nbsp;订单号：{{ item.order_sn
            }}</span>
            <span class="addr" v-if="value == 'order_sn'">联系人：{{ item.consignee }}&nbsp;&nbsp;&nbsp;&nbsp;电话：{{ item.phone
            }}</span>
            <span class="addr" v-if="value == 'phone'">联系人：{{ item.consignee }}&nbsp;&nbsp;&nbsp;&nbsp;订单号：{{
              item.order_sn }}</span>
          </template>
        </el-autocomplete>

        <el-button class="bth" round plain @click="handleSelect">查询</el-button>
      </div>
    </div>

    <div class="contents">
      <div v-for="(item, index) in iconsList" :key="index">
        <div class="aaa">
          <img :src="item.icons" @click="goPath(item)" />
          <div class="titles">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="footers">
      <div class="left">
        <template v-if="currentStore">
          <div @click="acquireStore">
            <i class="el-icon-s-shop"></i>{{ currentStore.name }}
            <template v-if="userInfo.user_store">
              <i class="el-icon-caret-bottom" v-if="userInfo.user_store.length > 0" style="color:#23B3F0"></i>
            </template>
          </div>
        </template>
        <div v-if="userInfo.mobile"><i class="el-icon-phone"></i>{{ userInfo.mobile }}</div>
        <div @click="goUser" v-if="userInfo.nickname"><i class="el-icon-user-solid"></i>{{ userInfo.nickname }}</div>
      </div>
      <div class="right">
        <!-- <div><i class="el-icon-s-data"></i>每日统计</div> -->
        <div @click="quit"><i class="el-icon-s-promotion"></i>退出</div>
      </div>
    </div>

    <el-dialog title="选择门店" :visible.sync="centerDialogVisible" width="50%" :close-on-click-modal="false"
      :show-close="false" :close-on-press-escape="false" center>
      <div>
        <template v-if="userInfo.group">
          <el-radio v-model="storeRadio" label="all" border
            v-if="userInfo.group.name == '洗涤师傅' || userInfo.group.name == '厂长'">无门店</el-radio>
        </template>

        <el-radio v-model="storeRadio" v-for="item in user_store" :label="item.id" border :key="item.id">{{
          item.name
        }}</el-radio>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getStoreIndex">确 定</el-button>
      </div>
    </el-dialog>

    <div class="sweep" @click="goOrders"><i class="el-icon-full-screen"></i>扫一扫</div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { timeFormat, throttle } from '../../common/util'
import fig from '../../../package.json'
export default {
  name: 'Index',
  data() {
    return {
      version: fig.version,
      value: 'phone',
      searchValue: '',
      timeout: null,
      optionList: [{
        value: 'consignee',
        label: '联系人'
      }, {
        value: 'order_sn',
        label: '订单号'
      }, {
        value: 'phone',
        label: '手机号'
      }],
      restaurants: [],
      iconsList: [
        {
          icons: require('../../assets/index/01.png'),
          path: '/pack',
          title: '收衣中心'
        },
        {
          icons: require('../../assets/index/02.png'),
          path: '/order',
          title: '订单中心'
        },
        {
          icons: require('../../assets/index/05.png'),
          path: '/recharge',
          title: '充值中心'
        },
        {
          icons: require('../../assets/index/03.png'),
          path: '/member',
          title: '会员中心'
        },
        {
          icons: require('../../assets/index/04.png'),
          path: '/addUser',
          title: '新增会员'
        },
        {
          icons: require('../../assets/index/06.png'),
          path: '/user',
          title: '个人中心'
        }
      ],
      userInfo: {},
      centerDialogVisible: false,
      storeRadio: '',
      user_store: []
    }
  },
  computed: {
    ...mapState(['currentStore', 'managerShow']),
  },
  mounted() {
    this.getIndex()
    this.getStore()
  },
  methods: {
    getIndex() {
      this.$http.get('user/index').then(res => {
        this.getStore()
        this.userInfo = res.data
        this.user_store = res.data.user_store
        if (this.currentStore == null) {
          console.log('走这里了吗', this.currentStore);
          if (res.data.group.name == "洗涤师傅" || res.data.group.name == "厂长") {
            let CurrentStoreObj = {
              id: 'all',
              name: '无门店'
            }
            localStorage.setItem('CurrentStore', JSON.stringify(CurrentStoreObj))
            this.$store.commit('setCurrentStore')
          } else {
            this.getStoreIndex()
          }
        }

        if (this.currentStore.id == 'all') {
          console.log('走这里了吗1', this.currentStore);
          this.$store.commit('setManagerShow', true)
        }
        // 
        // if (Object.keys(this.currentStore).length == 0) {
        //   this.getStoreIndex()
        // }

      }).catch(err => {
        console.log('err', err);
      })
    },
    getStore() {
      this.$http.get('store/index').then(res => {
        this.user_store = res.data
      }).catch(err => {
        console.log(err);
      })
    },
    acquireStore() {
      if (this.user_store.length > 0) {
        this.centerDialogVisible = true
      }
    },
    getStoreIndex() {
      if (this.storeRadio == 'all') {
        let CurrentStoreObj = {
          id: 'all',
          name: '无门店'
        }
        localStorage.setItem('CurrentStore', JSON.stringify(CurrentStoreObj))
        this.$store.commit('setCurrentStore')
        this.$store.commit('setManagerShow', true)
        this.centerDialogVisible = false
      } else {
        this.$http.get('store.store/index', {
          store_id: this.storeRadio
        }).then(res => {
          localStorage.setItem('CurrentStore', JSON.stringify(res.data))
          this.$store.commit('setCurrentStore')
          this.$store.commit('setManagerShow', false)
          this.centerDialogVisible = false
        }).catch(err => {
          this.centerDialogVisible = true
        })
      }
    },
    querySearch(value, cb) {
      let lastToday = new Date() //当天
      lastToday.setFullYear(lastToday.getFullYear() - 1)

      // 前年今天，今天
      let Arr = []
      Arr.push(timeFormat(lastToday.getTime(), 'yyyy-mm-dd'), timeFormat(new Date(), 'yyyy-mm-dd'))

      this.$http.get('store.order/index', {
        store_id: this.currentStore.id,
        date_type: 'custom',
        type: 'all',
        date: JSON.stringify(Arr),
        search: value
      }).then(res => {
        this.restaurants = []
        for (let i = 0; i < res.data.result.data.length; i++) {
          this.restaurants.push({
            id: res.data.result.data[i].id,
            consignee: res.data.result.data[i].consignee,
            phone: res.data.result.data[i].phone,
            order_sn: res.data.result.data[i].order_sn
          })
        }

        // value 输入的值 cb 回调
        // 查询到的数组
        let restaurants = this.restaurants;
        let results = value ? restaurants.filter(this.createFilter(value)) : restaurants;

        clearTimeout(this.timeout);

        // 调用 callback 返回建议列表的数据
        this.timeout = setTimeout(() => {
          cb(results);
        }, 500);

      }).catch(err => {
        console.log(err);
      })
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant[this.value].indexOf(queryString) != -1);
      };
    },
    light(item) {
      const Reg = new RegExp(this.searchValue, 'g')
      if (item) {
        return item[this.value].replace(Reg, `<span style="color: red">$&</span>`)
      }
    },
    // 选择
    handleSelect: throttle(function (item) {
      if (this.searchValue == '') {
        this.$message({
          message: '请输入您要查询的内容',
          type: 'warning'
        });
        return false
      }
      if (item.order_sn) {
        console.log(item.order_sn);
        this.$router.push({
          path: '/order',
          query: {
            order_sn: item.order_sn
          }
        })
      } else {
        this.$message.error('暂无查询到相关内容');
      }
    }),
    goPath(item) {
      if (item.path == '') {
        return false
      }
      // 验证是否为管理员且未选择门店
      if (item.path == '/pack' || item.path == '/addUser' || item.path == '/recharge' || item.path == '/member') {
        if (this.managerShow) {
          this.$message({
            message: '当前暂未绑定门店，请在首页点击门店然后先选择门店在重新执行操作',
            type: 'warning'
          })
          return false
        }
      }
      this.$router.push(item.path)
    },
    goUser() {
      this.$router.push('/user')
    },
    quit() {
      this.$confirm('确认是否退出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('user/logout').then(res => {
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
          localStorage.clear()
          // localStorage.setItem('CurrentStore', JSON.stringify({}))
          this.$store.commit('setCurrentStore')
          this.$router.push('login')
        }).catch(err => {
          console.log(err);
        })
      }).catch(() => {

      });
    },
    goOrders() {
      this.$router.push({
        name: 'Shelf',
        params: {
          isWrite: true
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.edition {
  position: fixed;
  z-index: 3;
  top: 8px;
  left: 8px;
  color: white;
  font-size: 14px;
}

.indexs {
  height: 100vh;
  box-sizing: border-box;
}

.headers {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--shallow-color-back);
  height: 110px;
}


.Logo {
  margin-right: 40px;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  color: white;

  img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }
}

.search {
  width: 40%;
  display: flex;
  align-items: center;
}

.bth {
  margin-left: 30px;
}

::v-deep .el-select .el-input {
  width: 120px;
  color: black;
  font-size: 15px;
}

.contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  top: 0;
  margin-top: 25px;

  .aaa {
    width: 320px;
    position: relative;
  }


  img {
    width: 320px;
    height: 320px;
    margin-right: 20px;
    box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.4);
    border-radius: 12px;
  }


  .titles {
    position: absolute;
    bottom: 10px;
    padding-bottom: 10;
    font-size: 26px;
    text-align: center;
    background: rgba(255, 255, 255, 0.3);
    left: 0;
    width: 320px;
    line-height: 40px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}



.contents>div {
  width: calc(100% / 3);
  padding: 10px 0;
  border-radius: 30px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.footers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin: 20px 80px 0;

  div {
    font-size: 18px;
    line-height: 30px;
  }

  i {
    margin-right: 5px;
    font-size: 22px;
  }
}

.left div {
  margin-right: 35px;
}

.right div {
  margin-left: 35px;
  cursor: pointer;
}

.footers>div {
  display: flex;
  align-items: center;
}

.addr {
  font-size: 12px;
  color: #b4b4b4;
}

@media screen and (max-width: 1366px) {
  .contents {

    .aaa {
      width: 280px;
    }


    img {
      width: 280px;
      height: 280px;
      margin-right: 20px;
    }


    .titles {
      width: 280px;
    }
  }
}

.sweep {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: white;
  position: fixed;
  top: 20px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  font-weight: 700;

  i {
    font-size: 35px;
    margin-bottom: 5px;
  }
}
</style>